<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo align-middle p-5 m-5">
    <div class="d-flex justify-content-center mb-3">
      <strong>Vérification de l'abonnement</strong>
    </div>
    <div class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import API from "@aws-amplify/api";
import { REFRESH_AUTH } from "@/core/services/store/auth.module";

export default {
  name: "success",
  components: {},
  async mounted() {
    if (this.$route.query.session_id) {
      await API.get("teleassistanceseniorstripeapi", "/checkout", {
        queryStringParameters: {
          sessionId: this.$route.query.session_id
        }
      });
    }
    await this.$store.dispatch(REFRESH_AUTH);
    this.$router.push({ name: "Dashboard" });
  },
  data() {
    return {};
  },
  methods: {},
  computed: {}
};
</script>
